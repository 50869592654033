import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../screens/Home";
import ContactUs from "../screens/ContactUs";
import OurStory from "../screens/OurStory";
import EV1 from "../screens/EV1";
import EV125 from "../screens/EV125";
import EV500 from "../screens/EV500";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import FAQ from "../screens/FAQ";
import Dealers from "../screens/Dealers";
import TestDrive from "../screens/TestDrive";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },

  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/about-us",
    element: <OurStory />,
  },

  {
    path: "/dealers",
    element: <Dealers />,
  },
  {
    path: "/book-a-test-drive",
    element: <TestDrive />,
  },

  {
    path: "/ev-1",
    element: <EV1 />,
  },

  {
    path: "/ev-125",
    element: <EV125 />,
  },
  {
    path: "/ev-500",
    element: <EV500 />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/faqs",
    element: <FAQ />,
  },
]);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
