import React from "react";
import Header from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";

const EV125 = () => {
  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>
      </div>

      {/* <!-- page preloader begin --> */}
      {/* <div id="de-loader"></div> */}
      {/* <!-- page preloader close --> */}

      {/* <!-- header begin --> */}
      <Header />
      {/* <!-- header close --> */}

      {/* <!-- content begin --> */}
      <div class="no-bottom no-top" id="content">
        <div id="top"></div>

        <section id="subheader" class="jarallax text-light bg-dark">
          <img src="images/background/5.5.webp" class="jarallax-img" alt="" />
          <div class="container relative z-index-1000">
            <div class="container relative z-index-1000">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <h5 class=" wow fadeInUp mb-2">
                    <span class="id-color">EV-125</span>
                  </h5>
                  <h1>Engineered to thrive on challenging Local roads.</h1>

                  <div class="row mt-3 g-2 text-center">
                    <div class="col-3">
                      <div class="bg-dark rounded-10px lh-1-2em p-2">
                        <i class="fs-40 mb-1 id-color icofont-map-pins"></i>
                        <div class="fs-18 fw-bold">110 KM</div>
                        <div class="fs-14 op-5">Range</div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="bg-dark rounded-10px lh-1-2em p-2">
                        <i class="fs-40 mb-1 id-color icofont-dashboard"></i>
                        <div class="fs-18 fw-bold">80 Km/h</div>
                        <div class="fs-14 op-5">Top Speed</div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="bg-dark rounded-10px lh-1-2em p-2">
                        <i class="fs-40 mb-1 id-color icofont-thunder-light"></i>
                        <div class="fs-18 fw-bold">2000 Watt</div>
                        <div class="fs-14 op-5">Motor Power</div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="bg-dark rounded-10px lh-1-2em p-2">
                        <i class="fs-40 mb-1 id-color icofont-thunder-light"></i>
                        <div class="fs-18 fw-bold">32AH/72V</div>
                        <div class="fs-14 op-5">Battery Capacity</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle bg-grey text-dark wow fadeInUp mb-3">
                  Spark Change
                </div>
                <h2 class="mb-0">
                  Make your day to day Ride Hassle free and Memorable
                </h2>
              </div>
              <div class="clearfix"></div>

              <div class="col-lg-8 offset-lg-2">
                <div class="relative">
                  <img
                    src="images/misc/ev-125-main.webp"
                    class="img-fluid wow scaleIn"
                    alt=""
                  />
                  {/* <div
                    class="de-dot mt-20"
                    style={{ left: "61%", top: "10%" }}
                    href="#"
                  >
                    <div class="d-content bg-color text-light">
                      <h5>Modern Headlamp</h5>
                      Eiusmod quis est do id excepteur ut mollit cupidatat quis
                      consequat cillum aute culpa aliqua ut dolor.
                    </div>
                  </div> */}
                  {/* <div
                    class="de-dot mt-20"
                    style={{ left: "68%", top: "77%" }}
                    href="#"
                  >
                    <div class="d-content bg-color text-light">
                      <h5>Large Wheels</h5>
                      Eiusmod quis est do id excepteur ut mollit cupidatat quis
                      consequat cillum aute culpa aliqua ut dolor.
                    </div>
                  </div> */}
                  {/* <div
                    class="de-dot mt-20"
                    style={{ left: "30%", top: "35%" }}
                    href="#"
                  >
                    <div class="d-content bg-color text-light">
                      <h5>Spacious Seat</h5>
                      Eiusmod quis est do id excepteur ut mollit cupidatat quis
                      consequat cillum aute culpa aliqua ut dolor.
                    </div>
                  </div> */}
                  {/* <div
                    class="de-dot mt-20"
                    style={{ left: "8%", top: "66%" }}
                    href="#"
                  >
                    <div class="d-content bg-color text-light">
                      <h5>Powerfull Motor</h5>
                      Eiusmod quis est do id excepteur ut mollit cupidatat quis
                      consequat cillum aute culpa aliqua ut dolor.
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="jarallax">
          <img
            src="images/background/gradient-2.webp"
            class="jarallax-img"
            alt=""
          />
          {/* <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle bg-white wow fadeInUp mb-3">
                  Tailored Made
                </div>
                <h2 class="mb-0">Power &amp; Precision</h2>
              </div>
              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".0s"
                >
                  <div>
                    <img
                      src="images/misc/p1.1.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Modern Headlamp</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".2s"
                >
                  <div>
                    <img
                      src="images/misc/p2.2.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Spacious Seat</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".4s"
                >
                  <div>
                    <img
                      src="images/misc/p3.3.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Large Wheels</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".6s"
                >
                  <div>
                    <img
                      src="images/misc/p4.4.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Quick Charge</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div class="col-lg-12 text-center">
            <div class="subtitle bg-white text-dark wow fadeInUp mb-3">
              Detailed
            </div>
            <h2 class="mb-0">Advanced Features that we offer</h2>
          </div>

          <section>
            <div class="container">
              <div class="row">
                <div class="col-lg-10 offset-lg-1">
                  <div class="site-timeline-content">
                    {/* <!-- Article --> */}
                    <div class="de-timeline-article odd">
                      <div class="site-center-line"></div>
                      <div class="content-left-container">
                        <div class="owl-single-dots owl-carousel owl-theme">
                          <div class="item">
                            <img src="images/timeline/1.1.webp" alt="" />
                          </div>
                          {/* <div class="item">
                            <img src="images/timeline/2.webp" alt="" />
                          </div>
                          <div class="item">
                            <img src="images/timeline/3.webp" alt="" />
                          </div> */}
                        </div>
                      </div>
                      <div class="content-right-container">
                        <div class="year__">
                          <div class="d-line"></div>
                          <h4 class="de-timeline-year">1-</h4>
                        </div>
                        <div class="content-right">
                          <h3 class="de-timeline-title">
                            3 different speed modes available
                          </h3>
                          <p>
                            <b>Eco:</b> Unlock the maximum efficiency and
                            extended battery life of the bike.
                          </p>
                          <p>
                            <b>City:</b>Ideal for daily commutes, providing you
                            optimal speed for navigating through traffic and
                            stops.
                          </p>
                          <p>
                            <b>Sports:</b> With its uninterrupted acceleration,
                            reach the bike’s highest speed.
                          </p>
                        </div>
                      </div>
                      <div class="meta-dot"></div>
                    </div>
                    {/* <!-- // Article --> */}
                    {/* <!-- Article --> */}
                    <div class="de-timeline-article even">
                      <div class="site-center-line"></div>
                      <div class="content-left-container">
                        <div class="year__">
                          <div class="d-line"></div>
                          <h4 class="de-timeline-year">2-</h4>
                        </div>
                        <div class="content-right">
                          <h3 class="de-timeline-title">
                            Lithium Power Cell Battery
                          </h3>
                          <p>
                            With 2,000 battery cycles, 2-hour fast charging, and
                            a range of up to 100 KM, to enjoy longer rides with
                            less time spent charging, making it easy for anyone
                            to sustain economic pressure, as our bikes cover 1
                            KM for just 1 rupee.
                          </p>
                        </div>
                      </div>
                      <div class="content-right-container">
                        <div class="owl-single-dots owl-carousel owl-theme">
                          <div class="item">
                            <img src="images/timeline/5.1.webp" alt="" />
                          </div>
                          {/* <div class="item">
                            <img src="images/timeline/5.webp" alt="" />
                          </div>
                          <div class="item">
                            <img src="images/timeline/6.webp" alt="" />
                          </div> */}
                        </div>
                      </div>
                      <div class="meta-dot"></div>
                    </div>
                    {/* <!-- // Article --> */}
                    {/* <!-- Article --> */}
                    <div class="de-timeline-article odd">
                      <div class="site-center-line"></div>
                      <div class="content-left-container">
                        <div class="owl-single-dots owl-carousel owl-theme">
                          <div class="item">
                            <img src="images/timeline/7.1.webp" alt="" />
                          </div>
                          {/* <div class="item">
                            <img src="images/timeline/8.webp" alt="" />
                          </div>
                          <div class="item">
                            <img src="images/timeline/9.webp" alt="" />
                          </div> */}
                        </div>
                      </div>
                      <div class="content-right-container">
                        <div class="year__">
                          <div class="d-line"></div>
                          <h4 class="de-timeline-year">3-</h4>
                        </div>
                        <div class="content-right">
                          <h3 class="de-timeline-title">Mobile App</h3>
                          <p>
                            Track the performance of your bike's range, speed
                            mode, distance, reduction in carbon emissions, fuel
                            savings, battery status, driver statics and much
                            more
                          </p>
                        </div>
                      </div>
                      <div class="meta-dot"></div>
                    </div>
                    {/* <!-- // Article --> */}
                    <div class="de-timeline-article even">
                      <div class="site-center-line"></div>
                      <div class="content-left-container">
                        <div class="year__">
                          <div class="d-line"></div>
                          <h4 class="de-timeline-year">2-</h4>
                        </div>
                        <div class="content-right">
                          <h3 class="de-timeline-title">17” Wheel Diameter</h3>
                          <p>
                            Optimized for stability and smooth handling, our
                            bikes feature a 17-inch wheel diameter, ensuring a
                            comfortable ride on challenging roads.
                          </p>
                        </div>
                      </div>
                      <div class="content-right-container">
                        <div class="owl-single-dots owl-carousel owl-theme">
                          <div class="item">
                            <img src="images/timeline/4.1.webp" alt="" />
                          </div>
                          {/* <div class="item">
                            <img src="images/timeline/5.webp" alt="" />
                          </div>
                          <div class="item">
                            <img src="images/timeline/6.webp" alt="" />
                          </div> */}
                        </div>
                      </div>
                      <div class="meta-dot"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section class="bg-grey">
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle bg-white text-dark wow fadeInUp mb-3">
                  Detailed
                </div>
                <h2 class="mb-0">Specifications</h2>
              </div>
              <div class="clearfix"></div>
              <div class="col-md-8 offset-lg-2 wow fadeInUp">
                <div class="accordion">
                  <div class="accordion-section">
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-1"
                    >
                      <h4 class="mb-0 d-inline">Motor</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-1">
                      <ul>
                        <li>Motor: 2000 W</li>
                        <li>Speed modes: Eco, City, Sports & Reverse</li>
                        <li>Charger: 10 A</li>
                      </ul>
                    </div>
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-2"
                    >
                      <h4 class="mb-0 d-inline">Battery</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-2">
                      <ul>
                        <li>Battery Capacity: 72V/32Ah</li>
                        <li>Battery Box: Waterproof</li>
                        <li>Fast Charging: 1 hour</li>
                        <li>Normal Charging: 2-3 Hours</li>
                      </ul>
                    </div>
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-3"
                    >
                      <h4 class="mb-0 d-inline">Performance</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-3">
                      <ul>
                        <li>Top speed: 100 km/hr</li>
                        <li>Maximum Range: 110 KM</li>
                      </ul>
                    </div>
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-4"
                    >
                      <h4 class="mb-0 d-inline">Chassis</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-4">
                      <ul>
                        <li>Tyres: Tubeless </li>
                        <li>Meter: Digital LED Meter</li>
                        <li>Lights: LED Headlamp, Tail Lamp and Indicators</li>
                        <li>Alloy rims: Both</li>
                        <li>Brakes: Front Drum, Rear Disc </li>
                      </ul>
                    </div>
                    {/* <div
                      class="accordion-section-title"
                      data-tab="#accordion-5"
                    >
                      <h4 class="mb-0 d-inline">Dimensions</h4>
                    </div> */}
                    {/* <div class="accordion-section-content" id="accordion-5">
                      <ul>
                        <li>Length: 2,000 mm</li>
                        <li>Width: 800 mm</li>
                        <li>Height: 1,100 mm</li>
                        <li>Seat height: 800 mm</li>
                        <li>Ground clearance: 150 mm</li>
                        <li>Weight: 180 kg (396 lbs)</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section class="jarallax">
          <img
            src="images/background/gradient-2.webp"
            class="jarallax-img"
            alt=""
          />
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle text-dark wow fadeInUp mb-3">Play</div>
                <h2 class="mb-0">Video</h2>
              </div>
              <div class="clearfix"></div>
              <div class="col-lg-8 offset-lg-2">
                <div class="row g-4 gx-5">
                  <div class="col-lg-6">
                    <div class="relative">
                      <a
                        href="https://www.youtube.com/embed/36FjQAudDk8"
                        class="popup-youtube"
                      >
                        <span class="absolute start-20px fs-18 bottom-20px text-light fw-600">
                          <i class="icofont-play-alt-1 me-2 id-color"></i> Play
                          Video
                        </span>
                        <img
                          src="images/misc/1.webp"
                          class="img-fluid rounded-20px"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="relative">
                      <a
                        href="https://www.youtube.com/embed/36FjQAudDk8"
                        class="popup-youtube"
                      >
                        <span class="absolute start-20px fs-18 bottom-20px text-light fw-600">
                          <i class="icofont-play-alt-1 me-2 id-color"></i> Play
                          Video
                        </span>
                        <img
                          src="images/misc/2.webp"
                          class="img-fluid rounded-20px"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section class="bg-color text-light pt40 pb40">
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-9">
                <h3 class="mb-1">Ready to drive into the future?</h3>
              </div>

              <div class="col-lg-3 text-lg-end text-start">
                <a class="btn-main bg-dark" href="/book-a-test-drive">
                  Book a Test Drive
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Content Close */}

      <Footer />
    </>
  );
};

export default EV125;
