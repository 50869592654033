import React from "react";
import { useState } from "react";
import Header from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";
import moment from "moment";
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const { PHONE_NUMBER, EMAIL, LOCATION, APP_URL } = window["appconfig"];
const MySwal = withReactContent(Swal);

const TestDrive = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [location, setLocation] = useState("");
  const [bikeModel, setBikeModel] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [demoTime, setDemoTime] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Handle form input changes
  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleBikeModelChange = (e) => {
    console.log(e.target.value, "bike model changed");
    setBikeModel(e.target.value);
  };
  const handleDateChange = (e) => setSelectedDate(e.target.value);
  const handleTimeChange = (e) => {
    // Parse time and add AM/PM
    const formattedTime = moment(e.target.value, "HH:mm").format("hh:mm A");

    console.log(formattedTime, "time---");
    setDemoTime(formattedTime);
    setSelectedTime(e.target.value);
  };

  const bikeModels = [
    { id: 1, name: "EV-1" },
    { id: 2, name: "EV-125" },
    { id: 3, name: "EV-500" },
  ];

  // post request using fetch
  // const handleSubmit = async (e) => {
  //   e.preventDefault(); // Prevent page reload

  //   console.log("Button clicked");
  //   // Create form data object
  //   const formData = {
  //     name,
  //     phone,
  //     email,
  //     message,
  //   };

  //   console.log(formData, "Form data");

  //   // // Example API call (replace with your actual logic)
  //   try {
  //     const response = await fetch("http://localhost:3000/api/submit-form", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     if (response.ok) {
  //       setSuccessMessage("Your message has been sent successfully.");
  //       setErrorMessage("");
  //       setName("");
  //       setEmail("");
  //       setPhone("");
  //       setMessage("");
  //     } else {
  //       setErrorMessage("Sorry, there was an error sending your form.");
  //       setSuccessMessage("");
  //     }
  //   } catch (error) {
  //     setErrorMessage("Sorry, there was an error sending your form.");
  //     setSuccessMessage("");
  //   }
  // };

  // Post request using axios

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload

    console.log("Button clicked");
    setIsLoading(true);

    // Create form data object
    const formData = {
      name,
      phone,
      email,
      location,
      bikeModel,
      demoDate: moment(selectedDate).format("MMMM Do YYYY"), // Format date,
      demoTime,
    };

    console.log(formData, "Form data");

    // Axios POST request
    try {
      const response = await axios.post(`${APP_URL}api/test-drive`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response, "axios post request response----");

      if (response.status === 200) {
        // response.ok is equivalent to checking for status code 200 in axios
        // setSuccessMessage("Test Drive Data has been posted successfully.");
        // toast.success("Test Drive Data has been posted successfully.");
        MySwal.fire({
          title: "Success!",
          text: "Ready, set, ride! Your test drive is on the way. Can’t wait to see you!",
          icon: "success",
          confirmButtonText: "OK",
        });
        // setErrorMessage("");
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setLocation("");
        setBikeModel("");
        setSelectedDate("");
        setSelectedTime("");
        setDemoTime("");

        setIsLoading(false);
      } else {
        console.log("erorr if else");
        // setErrorMessage("Sorry, there was an error sending your form.");
        // toast.error("There was an issue submitting your test drive data.");
        MySwal.fire({
          title: "Error!",
          text: "There was an issue submitting your test drive data.",
          icon: "error",
          confirmButtonText: "OK",
        });
        setSuccessMessage("");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, "erorr catch");
      // setErrorMessage("Sorry, there was an error sending your form.");
      // toast.error("An error occurred while sending your form.");
      MySwal.fire({
        title: "Error!",
        text: "There was an issue submitting your test drive data.",
        icon: "error",
        confirmButtonText: "OK",
      });
      // setSuccessMessage("");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>
        {/* <!-- page preloader begin --> */}
        {/* <div id="de-loader"></div> */}
        {/* <!-- page preloader close --> */}

        {/* <!-- header begin --> */}
        <Header />
        {/* <!-- header close --> */}

        {/* <!-- content begin --> */}
        <div class="no-bottom no-top" id="content">
          <div id="top"></div>
          <section class="pt10 pb10 mt80 bg-grey">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <h3 class="mb-0">
                    Book A Test Drive
                    <i class="icofont-dotted-right id-color ms-2"></i>
                  </h3>
                </div>

                <div class="col-lg-6">
                  <ul class="crumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li class="active">Book A Test Drive</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="container">
              <div class="row g-4 gx-5">
                <div class="col-lg-8">
                  <p>
                    Whether you have a question, a suggestion, or just want to
                    say hello, this is the place to do it. Please fill out the
                    form below with your details and message, and we'll get back
                    to you as soon as possible.
                  </p>

                  <form
                    name="contactForm"
                    id="contact_form"
                    class="position-relative z1000"
                    method="post"
                    action="#"
                    onSubmit={handleSubmit}
                  >
                    <div class="row gx-4">
                      <div class="col-lg-6 col-md-6 mb10">
                        <div class="field-set">
                          <span class="d-label fw-bold">Name</span>
                          <input
                            type="text"
                            name="Name"
                            id="name"
                            class="form-control"
                            placeholder="Your Name"
                            required
                            value={name}
                            onChange={handleNameChange}
                          />
                        </div>

                        <div class="field-set">
                          <span class="d-label fw-bold">Email</span>
                          <input
                            type="email"
                            name="Email"
                            id="email"
                            class="form-control"
                            placeholder="Your Email"
                            value={email}
                            onChange={handleEmailChange}
                          />
                        </div>

                        <div class="field-set">
                          <span class="d-label fw-bold">Phone</span>
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            class="form-control mb-4"
                            placeholder="Your Phone"
                            value={phone}
                            onChange={handlePhoneChange}
                            maxLength="13"
                            required
                          />
                        </div>

                        <div class="field-set">
                          <span class="d-label fw-bold">Location</span>
                          <input
                            type="text"
                            name="Location"
                            id="location"
                            class="form-control"
                            placeholder="Select A Location"
                            required
                            value={location}
                            onChange={handleLocationChange}
                          />
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6">
                        {/* Bike Model dropdown */}
                        <div className="field-set mb-3">
                          <span className="d-label fw-bold">Bike Model</span>
                          <select
                            className="form-select dropdown-arrow-right" // Add custom class
                            value={bikeModel}
                            onChange={handleBikeModelChange}
                            required
                          >
                            <option value="">Select a Bike Model</option>
                            {bikeModels.map((model) => (
                              <option key={model.id} value={model.name}>
                                {model.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Date picker */}
                        <div className="field-set mb-3">
                          <span className="d-label fw-bold">Select Date</span>
                          <input
                            type="date"
                            className="form-control"
                            required
                            value={selectedDate}
                            onChange={handleDateChange}
                          />
                        </div>

                        {/* Time picker */}
                        <div className="field-set mb-3">
                          <span className="d-label fw-bold">Select Time</span>
                          <input
                            type="time"
                            className="form-control"
                            required
                            value={selectedTime}
                            onChange={handleTimeChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="g-recaptcha"
                      data-sitekey="copy-your-sitekey-here"
                    ></div>
                    <div id="submit" class="mt20">
                      <input
                        type="submit"
                        id="send_message"
                        value={isLoading ? "Loading..." : "Submit"}
                        class="btn-main"
                        disabled={isLoading}
                      />
                    </div>

                    {successMessage && (
                      <div id="success_message" className="success">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage.length > 0 && (
                      <div id="error_message" className="error">
                        {errorMessage}
                      </div>
                    )}
                  </form>
                </div>

                <div class="col-lg-4">
                  <h4>Our Office</h4>
                  <div class="img-with-cap mb20">
                    <div class="d-title">Mon - Fri 10.00am - 6.00pm</div>
                    <div class="d-overlay"></div>
                    <img
                      src="images/misc/5.webp"
                      class="img-fullwidth rounded-1"
                      alt=""
                    />
                  </div>
                  <div class="spacer-single"></div>
                  <div class="fw-bold text-dark">
                    <i class="icofont-location-pin me-2 id-color-2"></i>Office
                    Location
                  </div>
                  {LOCATION}
                  <div class="spacer-single"></div>
                  <div class="fw-bold text-dark">
                    <i class="icofont-envelope me-2 id-color-2"></i>Send a
                    Message
                  </div>
                  {EMAIL}
                  <div class="spacer-single"></div>
                  <div class="fw-bold text-dark">
                    <i class="icofont-phone me-2 id-color-2"></i>Call Us
                    Directly
                  </div>
                  {PHONE_NUMBER}
                  <div class="spacer-single"></div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <!-- content close --> */}
        <Footer />
      </div>
    </>
  );
};

export default TestDrive;
