import React from "react";
import Header from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";

const Dealers = () => {
  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>

        {/* <!-- page preloader begin --> */}
        {/* <div id="de-loader"></div> */}
        {/* <!-- page preloader close --> */}

        <Header />

        {/* <!-- content begin --> */}
        <div class="no-bottom no-top" id="content">
          <div id="top"></div>

          <section class="pt10 pb10 mt80 bg-grey">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <h3 class="mb-0">
                    Dealers<i class="icofont-dotted-right id-color ms-2"></i>
                  </h3>
                </div>

                <div class="col-lg-6 text-lg-start">
                  <ul class="crumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li class="active">Dealers</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="container">
              <div class="row g-5">
                <div class="col-lg-4">
                  <div class="img-with-cap mb20">
                    <div class="d-title">
                      <span class="me-2">Mon - Fri</span>
                      <span>08.00 - 18.00</span>
                    </div>
                    <div class="d-overlay"></div>
                    <img
                      src="images/dealers/1.webp"
                      class="img-fullwidth rounded-1"
                      alt=""
                    />
                  </div>

                  <h4>Bahria Town (Muneeb & Co.) Karachi</h4>

                  <div class="fw-500">
                    <i class="icofont-location-pin me-2 id-color-2"></i>Shop #
                    01 B-142 Midway Commercial, Bahria Town Karachi
                  </div>
                  {/* <div class="fw-500">
                    <i class="icofont-envelope me-2 id-color-2"></i>
                    contact@autoev.com
                  </div>
                  <div class="fw-500 mb-3">
                    <i class="icofont-phone me-2 id-color-2"></i>+929 333 9296
                  </div>
                  <a href="#" class="btn-text text-dark">
                    Get Direction
                  </a> */}
                </div>

                <div class="col-lg-4">
                  <div class="img-with-cap mb20">
                    <div class="d-title">
                      <span class="me-2">Mon - Fri</span>
                      <span>08.00 - 18.00</span>
                    </div>
                    <div class="d-overlay"></div>
                    <img
                      src="images/dealers/2.webp"
                      class="img-fullwidth rounded-1"
                      alt=""
                    />
                  </div>

                  <h4>Gulshan-e-Iqbal (Ajj Enterprises) Karachi</h4>

                  <div class="fw-500">
                    <i class="icofont-location-pin me-2 id-color-2"></i>Plot No.
                    L-155, Block 13-G, Gulshan-e-Iqbal, Karachi
                  </div>
                  {/* <div class="fw-500">
                    <i class="icofont-envelope me-2 id-color-2"></i>
                    contact@autoev.com
                  </div>
                  <div class="fw-500 mb-3">
                    <i class="icofont-phone me-2 id-color-2"></i>+929 333 9296
                  </div>
                  <a href="#" class="btn-text text-dark">
                    Get Direction
                  </a> */}
                </div>

                <div class="col-lg-4">
                  <div class="img-with-cap mb20">
                    <div class="d-title">
                      <span class="me-2">Mon - Fri</span>
                      <span>08.00 - 18.00</span>
                    </div>
                    <div class="d-overlay"></div>
                    <img
                      src="images/dealers/3.webp"
                      class="img-fullwidth rounded-1"
                      alt=""
                    />
                  </div>

                  <h4>Hilal Motors</h4>

                  <div class="fw-500">
                    <i class="icofont-location-pin me-2 id-color-2"></i>Khalid
                    Bin Waleed Motors
                  </div>
                  {/* <div class="fw-500">
                    <i class="icofont-envelope me-2 id-color-2"></i>
                    contact@autoev.com
                  </div>
                  <div class="fw-500 mb-3">
                    <i class="icofont-phone me-2 id-color-2"></i>+929 333 9296
                  </div>
                  <a href="#" class="btn-text text-dark">
                    Get Direction
                  </a> */}
                </div>

                {/* <div class="col-lg-4">
                  <div class="img-with-cap mb20">
                    <div class="d-title">
                      <span class="me-2">Mon - Fri</span>
                      <span>08.00 - 18.00</span>
                    </div>
                    <div class="d-overlay"></div>
                    <img
                      src="images/dealers/4.webp"
                      class="img-fullwidth rounded-1"
                      alt=""
                    />
                  </div>

                  <h4>New Delhi</h4>

                  <div class="fw-500">
                    <i class="icofont-location-pin me-2 id-color-2"></i>321,
                    Connaught Place, New Delhi 110001
                  </div>
                  <div class="fw-500">
                    <i class="icofont-envelope me-2 id-color-2"></i>
                    contact@autoev.com
                  </div>
                  <div class="fw-500 mb-3">
                    <i class="icofont-phone me-2 id-color-2"></i>+929 333 9296
                  </div>
                  <a href="#" class="btn-text text-dark">
                    Get Direction
                  </a>
                </div>

                <div class="col-lg-4">
                  <div class="img-with-cap mb20">
                    <div class="d-title">
                      <span class="me-2">Mon - Fri</span>
                      <span>08.00 - 18.00</span>
                    </div>
                    <div class="d-overlay"></div>
                    <img
                      src="images/dealers/5.webp"
                      class="img-fullwidth rounded-1"
                      alt=""
                    />
                  </div>

                  <h4>Ankara</h4>

                  <div class="fw-500">
                    <i class="icofont-location-pin me-2 id-color-2"></i>123
                    Atatürk Boulevard 06520 Ankara
                  </div>
                  <div class="fw-500">
                    <i class="icofont-envelope me-2 id-color-2"></i>
                    contact@autoev.com
                  </div>
                  <div class="fw-500 mb-3">
                    <i class="icofont-phone me-2 id-color-2"></i>+929 333 9296
                  </div>
                  <a href="#" class="btn-text text-dark">
                    Get Direction
                  </a>
                </div>

                <div class="col-lg-4">
                  <div class="img-with-cap mb20">
                    <div class="d-title">
                      <span class="me-2">Mon - Fri</span>
                      <span>08.00 - 18.00</span>
                    </div>
                    <div class="d-overlay"></div>
                    <img
                      src="images/dealers/6.webp"
                      class="img-fullwidth rounded-1"
                      alt=""
                    />
                  </div>

                  <h4>Berlin</h4>

                  <div class="fw-500">
                    <i class="icofont-location-pin me-2 id-color-2"></i>789
                    Potsdamer Platz 10785 Berlin
                  </div>
                  <div class="fw-500">
                    <i class="icofont-envelope me-2 id-color-2"></i>
                    contact@autoev.com
                  </div>
                  <div class="fw-500 mb-3">
                    <i class="icofont-phone me-2 id-color-2"></i>+929 333 9296
                  </div>
                  <a href="#" class="btn-text text-dark">
                    Get Direction
                  </a>
                </div> */}
              </div>
            </div>
          </section>
        </div>
        {/* <!-- content close --> */}

        <Footer />
      </div>
    </>
  );
};

export default Dealers;
