import React from "react";
import Header from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";

const EV1 = () => {
  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>
      </div>

      {/* <!-- page preloader begin --> */}
      {/* <div id="de-loader"></div> */}
      {/* <!-- page preloader close --> */}

      {/* <!-- header begin --> */}
      <Header />
      {/* <!-- header close --> */}

      {/* <!-- content begin --> */}
      <div class="no-bottom no-top" id="content">
        <div id="top"></div>

        <section id="subheader" class="jarallax text-light bg-dark">
          <img src="images/background/5.6.webp" class="jarallax-img" alt="" />
          <div class="container relative z-index-1000">
            <div class="container relative z-index-1000">
              <div class="row align-items-center">
                <div class="col-lg-5">
                  <h5 class=" wow fadeInUp mb-2">
                    <span class="id-color">EV-1</span>
                  </h5>
                  <h1>Ride the Future: Silent, Smooth, Electric</h1>

                  <div class="row mt-3 g-2 text-center">
                    <div class="col-4">
                      <div class="bg-dark rounded-10px lh-1-2em p-2">
                        <i class="fs-40 mb-1 id-color icofont-map-pins"></i>
                        <div class="fs-18 fw-bold">110 KM</div>
                        <div class="fs-14 op-5">Range</div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="bg-dark rounded-10px lh-1-2em p-2">
                        <i class="fs-40 mb-1 id-color icofont-dashboard"></i>
                        <div class="fs-18 fw-bold">50 Km/h</div>
                        <div class="fs-14 op-5">Top Speed</div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="bg-dark rounded-10px lh-1-2em p-2">
                        <i class="fs-40 mb-1 id-color icofont-thunder-light"></i>
                        <div class="fs-18 fw-bold">1500 Watt</div>
                        <div class="fs-14 op-5">Motor Power</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle bg-grey text-dark wow fadeInUp mb-3">
                  Spark Change
                </div>
                <h2 class="mb-0">Detailed Features</h2>
              </div>
              <div class="clearfix"></div>

              <div class="col-lg-8 offset-lg-2">
                <div class="relative">
                  <img
                    src="images/misc/m1.webp"
                    class="img-fluid wow scaleIn"
                    alt=""
                  />
                  <div
                    class="de-dot mt-20"
                    style={{ left: "61%", top: "10%" }}
                    href="#"
                  >
                    <div class="d-content bg-color text-light">
                      <h5>Modern Headlamp</h5>
                      Eiusmod quis est do id excepteur ut mollit cupidatat quis
                      consequat cillum aute culpa aliqua ut dolor.
                    </div>
                  </div>
                  <div
                    class="de-dot mt-20"
                    style={{ left: "68%", top: "77%" }}
                    href="#"
                  >
                    <div class="d-content bg-color text-light">
                      <h5>Large Wheels</h5>
                      Eiusmod quis est do id excepteur ut mollit cupidatat quis
                      consequat cillum aute culpa aliqua ut dolor.
                    </div>
                  </div>
                  <div
                    class="de-dot mt-20"
                    style={{ left: "30%", top: "35%" }}
                    href="#"
                  >
                    <div class="d-content bg-color text-light">
                      <h5>Spacious Seat</h5>
                      Eiusmod quis est do id excepteur ut mollit cupidatat quis
                      consequat cillum aute culpa aliqua ut dolor.
                    </div>
                  </div>
                  <div
                    class="de-dot mt-20"
                    style={{ left: "8%", top: "66%" }}
                    href="#"
                  >
                    <div class="d-content bg-color text-light">
                      <h5>Powerfull Motor</h5>
                      Eiusmod quis est do id excepteur ut mollit cupidatat quis
                      consequat cillum aute culpa aliqua ut dolor.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="jarallax">
          <img
            src="images/background/gradient-2.webp"
            class="jarallax-img"
            alt=""
          />
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle bg-white wow fadeInUp mb-3">
                  Tailored Made
                </div>
                <h2 class="mb-0">Power &amp; Precision</h2>
              </div>
              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".0s"
                >
                  <div>
                    <img
                      src="images/misc/p1.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Modern Headlamp</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".2s"
                >
                  <div>
                    <img
                      src="images/misc/p2.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Spacious Seat</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".4s"
                >
                  <div>
                    <img
                      src="images/misc/p3.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Large Wheels</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".6s"
                >
                  <div>
                    <img
                      src="images/misc/p4.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Quick Charge</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bg-grey">
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle bg-white text-dark wow fadeInUp mb-3">
                  Detailed
                </div>
                <h2 class="mb-0">Specifications</h2>
              </div>
              <div class="clearfix"></div>
              <div class="col-md-8 offset-lg-2 wow fadeInUp">
                <div class="accordion">
                  <div class="accordion-section">
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-1"
                    >
                      <h4 class="mb-0 d-inline">Motor</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-1">
                      <ul>
                        <li>Charger 10A</li>
                        <li>Motor: 1500W</li>
                      </ul>
                    </div>
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-2"
                    >
                      <h4 class="mb-0 d-inline">Battery</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-2">
                      <ul>
                        <li>Battery Capacity: 60V/32Ah</li>
                        <li>Battery Box: Waterproof</li>
                        <li>Fast Charging: 1 Hour</li>
                        <li>Normal Charging - 2-3 hours</li>
                      </ul>
                    </div>
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-3"
                    >
                      <h4 class="mb-0 d-inline">Performance</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-3">
                      <ul>
                        <li>Top speed: 50KM/H</li>
                        <li>Maximum Range: 110km</li>
                        <li>Speed modes: (3 Modes) Eco, City & Reverse</li>
                      </ul>
                    </div>
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-4"
                    >
                      <h4 class="mb-0 d-inline">Chassis</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-4">
                      <ul>
                        <li>Tyres: Tubeless</li>
                        <li>Meter: Digital LED</li>
                        <li>Lights: LED Headlamp, Tail Lamp and Indicators</li>
                        <li>Alloy rims: Both</li>
                        <li>Disc Brakes: Front Disc, Rear Drum</li>
                      </ul>
                    </div>
                    {/* <div
                      class="accordion-section-title"
                      data-tab="#accordion-5"
                    >
                      <h4 class="mb-0 d-inline">Dimensions</h4>
                    </div> */}
                    {/* <div class="accordion-section-content" id="accordion-5">
                      <ul>
                        <li>Length: 2,000 mm</li>
                        <li>Width: 800 mm</li>
                        <li>Height: 1,100 mm</li>
                        <li>Seat height: 800 mm</li>
                        <li>Ground clearance: 150 mm</li>
                        <li>Weight: 180 kg (396 lbs)</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle bg-grey text-dark wow fadeInUp mb-3">
                  Spark Change
                </div>
                <h2 class="mb-0">Advanced Features</h2>
              </div>

              {/* <div class="col-lg-8">
                <div class="subtitle bg-grey text-dark wow fadeInUp mb-3">
                  Spark Change
                </div>
                <h2 class="wow fadeInUp">Advanced Features</h2>
              </div>
              <div class="col-lg-4 text-lg-end">
                <a class="btn-text text-dark wow fadeInLeft" href="#">
                  Discover all features
                </a>
              </div> */}
              <div class="col-lg-4">
                <div class="mb-4 relative wow fadeInRight" data-wow-delay=".3s">
                  {/* <div class="absolute top-0 end-0">01</div> */}
                  <i class="bg-color text-light fs-48 p-2 absolute id-color icofont-thunder-light rounded-10px"></i>
                  <div class="pl-90">
                    <h4>Lithium-ion Batteries</h4>
                    <p class="mb-0">
                      Enjoy long-lasting performance with our high-capacity
                      Lithium-ion batteries,designed for efficiency.
                    </p>
                  </div>
                </div>

                <div class="mb-4 relative wow fadeInRight" data-wow-delay=".5s">
                  <div class="absolute top-0 end-0">02</div>
                  <i class="bg-dark text-light fs-48 p-2 absolute id-color icofont-power rounded-10px"></i>
                  <div class="pl-90">
                    <h4>Kill Switch</h4>
                    <p class="mb-0">
                      Instantly disable your vehicle with our advanced kill
                      switch, ensuring maximum security
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <img
                  src="images/misc/6.webp"
                  class="img-fluid wow scaleIn"
                  alt=""
                />
              </div>
              <div class="col-lg-4">
                <div class="mb-4 relative wow fadeInRight" data-wow-delay=".3s">
                  {/* <div class="absolute top-0 end-0">01</div> */}
                  <i class="bg-color text-light fs-48 p-2 absolute id-color icofont-shield-alt rounded-10px"></i>
                  <div class="pl-90">
                    <h4>Geo Fencing</h4>
                    <p class="mb-0">
                      Define your vehicle's boundaries with geo-fencing,
                      receiving instant alerts if it leaves a designated area.
                    </p>
                  </div>
                </div>

                <div class="mb-4 relative wow fadeInRight" data-wow-delay=".5s">
                  {/* <div class="absolute top-0 end-0">02</div> */}
                  <i class="bg-dark text-light fs-48 p-2 absolute id-color icofont-automation rounded-10px"></i>
                  <div class="pl-90">
                    <h4>Reverse Mode</h4>
                    <p class="mb-0">
                      Effortlessly navigate tight spaces with our convenient
                      reverse mode, designed for smooth and safe maneuvering.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="jarallax">
          <img
            src="images/background/gradient-2.webp"
            class="jarallax-img"
            alt=""
          />
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle text-dark wow fadeInUp mb-3">Play</div>
                <h2 class="mb-0">Video</h2>
              </div>
              <div class="clearfix"></div>
              <div class="col-lg-8 offset-lg-2">
                <div class="row g-4 gx-5">
                  <div class="col-lg-6">
                    <div class="relative">
                      <a
                        href="https://www.youtube.com/embed/36FjQAudDk8"
                        class="popup-youtube"
                      >
                        <span class="absolute start-20px fs-18 bottom-20px text-light fw-600">
                          <i class="icofont-play-alt-1 me-2 id-color"></i> Play
                          Video
                        </span>
                        <img
                          src="images/misc/1.webp"
                          class="img-fluid rounded-20px"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="relative">
                      <a
                        href="https://www.youtube.com/embed/36FjQAudDk8"
                        class="popup-youtube"
                      >
                        <span class="absolute start-20px fs-18 bottom-20px text-light fw-600">
                          <i class="icofont-play-alt-1 me-2 id-color"></i> Play
                          Video
                        </span>
                        <img
                          src="images/misc/2.webp"
                          class="img-fluid rounded-20px"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bg-color text-light pt40 pb40">
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-9">
                <h3 class="mb-1">Ready to drive into the future?</h3>
              </div>

              <div class="col-lg-3 text-lg-end text-start">
                <a class="btn-main bg-dark" href="contact.html">
                  Book a Test Drive
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Content Close */}

      <Footer />
    </>
  );
};

export default EV1;
